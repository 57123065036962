.card_input {
    border: none;
    border-bottom: 1px solid #D2DBE3;
    filter: drop-shadow(0px 2px 6px rgba(19, 18, 66, 0.07));
    border-radius: 4px;
    height: 100%;
    width: 100%;
    padding: 0 10px;
}

.checkout form {
    display: flex;
}

.desktop_version {
    display: block;

}
.mobile_version{
    display: none;
}
@media only screen and (max-width: 768px) {
    .desktop_version{
        display:none;
    }
    .mobile_version{
        display:block;
    }
}